import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Esta pagina no existe</h1>
    <p>La ruta ingresada no existe en el programa</p>
  </Layout>
)

export default NotFoundPage
